export const profileActionTypes = {
  RESET_ALL: 'RESET_ALL',

  GET_PROFILE_INFO: 'GET_PROFILE_INFO',
  GET_PROFILE_INFO_SUCCESS: 'GET_PROFILE_INFO_SUCCESS',
  GET_PROFILE_INFO_FAIL: 'GET_PROFILE_INFO_FAIL',
  SOUND_SWITCHER: 'SOUND_SWITCHER',

  SET_SELECTED_SORT_LIST_OF_DUELS: 'SET_SELECTED_SORT_LIST_OF_DUELS',

  CLEAR_ERROR: 'CLEAR_ERROR',

  // UPDATE NICKNAME
  UPDATE_NICKNAME: 'UPDATE_NICKNAME',
  UPDATE_NICKNAME_SUCCESS: 'UPDATE_NICKNAME_SUCCESS',
  UPDATE_NICKNAME_FAIL: 'UPDATE_NICKNAME_FAIL',
};
