import React from 'react';
import { ComponentType } from 'react';
import { FriendsPageInfo } from './My.styled';

import FriendsPageInfoListSection from './components/FriendsPageInfoList';
import FriendsPageInfoButtonsSection from './components/FriendsPageInfoButtons';

interface InfoProps {}

const FriendsInfo: ComponentType<InfoProps> = () => {
  return (
    <>
      <FriendsPageInfo>
        <FriendsPageInfoListSection />
        <FriendsPageInfoButtonsSection />
      </FriendsPageInfo>
    </>
  );
};

export default FriendsInfo;
