import styled from 'styled-components';
import HeaderSeparatorLine from '@icons/friends/header-separator_line.png';

export const FriendsPageHeader = styled.header`
  position: sticky;
  top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 13px 0;
  z-index: 10;

  background: linear-gradient(180deg, #0f1d48 0%, #111633 100%);

  img {
    position: absolute;
    width: 23px;
    height: 15px;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url(${HeaderSeparatorLine});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
`;
export const FriendsPageHeaderText = styled.h4`
  color: #7ea2df;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
