import React from 'react';
import { ComponentType } from 'react';
import { LoadingSpinStyle } from './My.styled';

interface LoadingSpinProps {}

const LoadingSpin: ComponentType<LoadingSpinProps> = () => (
  <LoadingSpinStyle className="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </LoadingSpinStyle>
);

export default LoadingSpin;
