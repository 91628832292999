import styled, { keyframes } from 'styled-components';

const LoadingSpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  `;

export const LoadingSpinStyle = styled.div`
  &.lds-ring {
    color: #8aaeec;
  }
  &.lds-ring,
  &.lds-ring div {
    box-sizing: border-box;
  }
  &.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  &.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid currentColor;
    border-radius: 50%;
    animation: ${LoadingSpinAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1)
      infinite;
    border-color: currentColor transparent transparent transparent;
  }
  &.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  &.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  &.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
