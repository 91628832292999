import React, { useEffect } from 'react';
import { ComponentType } from 'react';
import { FriendsPageConatiner, FriendsPageWrapper } from './My.styled';

import FriendsPageHeaderSection from './components/FriendsPageHeader';
import FriendsInfo from './components/FriendsPageInfo';
import FriendsPageContentSection from './components/FriendsPageContent';
import FriendsPageBringSection from './components/FriendsPageBring';
import { getMainInfo } from '@redux/dashboard/actions';
import { createStructuredSelector } from 'reselect';
import { selectMainInfo } from '@redux/dashboard/selectors';
import { connect } from 'react-redux';
import { IGetMainResponseModel } from '@common/types/dashboard.types';
import { useIntl } from 'react-intl';
import { getProfileInfo } from '@redux/profile/action';
import { getFriendsList } from '@redux/referral/action';
import DashboardNav from '@views/DashboardScreen/components/DashboardNav';
import { apiCache } from '@api/configAPI';

interface FriendsScreenProps {
  handleGetMainInfo: (params: any) => Promise<void>;
  handleGetProfileInfo: (params: any) => Promise<void>;
  mainInfoData: null | IGetMainResponseModel;
}

const FriendsScreen: ComponentType<FriendsScreenProps> = ({
  handleGetMainInfo,
  handleGetProfileInfo,
}) => {
  const intl = useIntl();

  useEffect(() => {
    handleGetMainInfo({ intl });
    handleGetProfileInfo({ intl });

    apiCache.resetCache('/main/profile');
  }, []);

  return (
    <>
      <FriendsPageConatiner>
        <FriendsPageWrapper>
          <FriendsPageHeaderSection />
          <FriendsInfo />
          <FriendsPageContentSection />
          <FriendsPageBringSection />
        </FriendsPageWrapper>

        <DashboardNav />
      </FriendsPageConatiner>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  mainInfoData: selectMainInfo,
});

const mapDispatchToProps = {
  handleGetMainInfo: getMainInfo,
  handleGetProfileInfo: getProfileInfo,
  handleGetFriendsList: getFriendsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(FriendsScreen);
