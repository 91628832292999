import * as React from 'react';

function ConnectionLost(props: any) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1000005518">
        <rect
          id="Rectangle 12249"
          x="1"
          y="1"
          width="42"
          height="42"
          rx="8"
          stroke="#A5113F"
          stroke-width="2"
        />
        <g id="Vector">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.0013 24.7262C19.4921 24.7262 18.2828 26.2315 18.1447 26.4936C17.8215 27.1069 17.0519 27.3475 16.4257 27.031C15.7995 26.7144 15.5538 25.9606 15.877 25.3472C16.3828 24.3874 18.3932 22.2267 22.0013 22.2267C25.6095 22.2267 27.6199 24.3874 28.1256 25.3472C28.4488 25.9606 28.2032 26.7144 27.577 27.031C26.9508 27.3475 26.1812 27.1069 25.858 26.4936C25.7198 26.2315 24.5106 24.7262 22.0013 24.7262Z"
            fill="#FFB4B4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.9998 19.2277C16.2959 19.2277 13.5498 22.0745 13.1526 22.8281C12.8294 23.4414 12.0598 23.682 11.4336 23.3654C10.8074 23.0489 10.5618 22.2951 10.885 21.6817C11.7757 19.9913 15.469 16.7282 21.9998 16.7282C28.5306 16.7282 32.2238 19.9913 33.1146 21.6817C33.4378 22.2951 33.1921 23.0489 32.5659 23.3654C31.9397 23.682 31.1701 23.4414 30.8469 22.8281C30.4498 22.0745 27.7037 19.2277 21.9998 19.2277Z"
            fill="#FFB4B4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.9136 15.1323C10.7859 16.2374 9.67966 17.4626 9.39326 17.9708C9.05272 18.5751 8.27653 18.7945 7.65959 18.461C7.04265 18.1275 6.81857 17.3672 7.15911 16.7629C7.75811 15.7 9.3081 14.1757 11.7181 12.9241C14.171 11.6502 17.5858 10.6182 22 10.6182C26.4142 10.6182 29.829 11.6502 32.2819 12.9241C34.6919 14.1757 36.2419 15.7 36.8409 16.7629C37.1814 17.3672 36.9574 18.1275 36.3404 18.461C35.7235 18.7945 34.9473 18.5751 34.6067 17.9708C34.3203 17.4626 33.2141 16.2374 31.0864 15.1323C29.0015 14.0495 25.9971 13.1176 22 13.1176C18.0029 13.1176 14.9985 14.0495 12.9136 15.1323Z"
            fill="#FFB4B4"
          />
          <path
            d="M24.4945 30.6742C24.4945 32.024 23.3773 33.1182 21.9993 33.1182C20.6212 33.1182 19.504 32.024 19.504 30.6742C19.504 29.3244 20.6212 28.2302 21.9993 28.2302C23.3773 28.2302 24.4945 29.3244 24.4945 30.6742Z"
            fill="#FFB4B4"
          />
        </g>
        <path
          id="X CROSS (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.93934 6.93934C8.52513 6.35355 9.47487 6.35355 10.0607 6.93934L33.0607 29.9393C33.6464 30.5251 33.6464 31.4749 33.0607 32.0607C32.4749 32.6464 31.5251 32.6464 30.9393 32.0607L7.93934 9.06066C7.35355 8.47487 7.35355 7.52513 7.93934 6.93934Z"
          fill="#BB2534"
        />
      </g>
    </svg>
  );
}
export default ConnectionLost;
