export const referralActionTypes = {
  RESET_ALL: 'RESET_ALL',
  CLEAR_ERROR: 'CLEAR_ERROR',

  GET_FRIENDS_LIST: 'GET_FRIENDS_LIST',
  GET_FRIENDS_LIST_SUCCESS: 'GET_FRIENDS_LIST_SUCCESS',
  GET_FRIENDS_LIST_FAIL: 'GET_FRIENDS_LIST_FAIL',

  POST_CLAIM_REWARD: 'POST_CLAIM_REWARD',
  POST_CLAIM_REWARD_SUCCESS: 'POST_CLAIM_REWARD_SUCCESS',
  POST_CLAIM_REWARD_FAIL: 'POST_CLAIM_REWARD_FAIL',

  GET_REFERRAL_RECEIVED_REWARD: 'GET_REFERRAL_RECEIVED_REWARD',
  GET_REFERRAL_RECEIVED_REWARD_SUCCESS: 'GET_REFERRAL_RECEIVED_REWARD_SUCCESS',
  GET_REFERRAL_RECEIVED_REWARD_FAIL: 'GET_REFERRAL_RECEIVED_REWARD_FAIL',
};
