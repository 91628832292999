export const AppConfig = {
  apiURL: 'https://api.cryptoparty.games/api',
  nodeApiURL: 'https://api-five-in-all.cryptoparty.games/api',
  socket: 'wss://api.cryptoparty.games/wss/:8092?',
  apiImages: 'http://api.cryptoparty.games/',
googleClientId:
    '28311037113-ddupknugrs1frpunh1o9m202kn5g5fuf.apps.googleusercontent.com',
  telegramAppUrl: 'https://t.me/CryptoPartyGame_bot',
};

export const DEFAULT_IMAGE_URLS = [
    'http://api.cryptoparty.games',
];

export const GA_CATEGORIES = {
    USER: 'User',
    GAME: 'Game',
    FINANCIAL: 'Financial',
}

export const IS_GA_ENABLED = true;
export const IS_GTM_ENABLED = true;

export const IS_DEMO_ENABLED = false;
export const IS_MAINTENANCE = false;
