import styled from 'styled-components/macro';
import { device } from '@shared/styles/device';
import { ToastContainer as OriginalToastContainer } from 'react-toastify';

export type ToastType =
  | 'connectionClosed'
  | 'connectionRestored'
  | 'connectionWeak'
  | string;

// Хелпер для получения цвета текста заголовка по типу
const getTitleColor = (type: ToastType): string => {
  switch (type) {
    case 'connectionClosed':
      return '#FFB4B4';
    case 'connectionRestored':
      return '#53FDA5';
    case 'connectionWeak':
      return '#F2994A';
    default:
      return '#7ea2df';
  }
};

export const ToastContainer = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
`;

export const LeftSidebar = styled.div`
  display: flex;
`;
export const CenterSidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 13px;
`;

export const RightSidebar = styled.div`
  display: flex;
`;

export const ConnectionLostImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
`;

export const ConnectionRestoreImageContainer = styled(
  ConnectionLostImageContainer
)``;

interface ToastTitleProps {
  toastType: ToastType;
}

export const ToastTitle = styled.h4<ToastTitleProps>`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => getTitleColor(props.toastType)};
  text-transform: uppercase;
  margin-bottom: 9px;
`;

export const ToastMessage = styled.p`
  color: #7ea2df;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media ${device.mobileLarge}, ${device.mobileSmall} {
    font-size: 12px;
  }
`;

export const StyledToastContainer = styled(OriginalToastContainer)`
  .Toastify__toast {
    border-radius: 0px 0px 11px 11px;
    background: #25428c;
    color: #7ea2df;
    height: 69px;
  }

  // Добавляем стили для разных типов toast-уведомлений
  .toast-connection-closed {
    box-shadow:
      0px 1px 0px 0px #ffb4b4,
      0px 4px 5.3px 0px rgba(0, 0, 0, 0.76);
  }

  .toast-connection-restored {
    box-shadow:
      0px 1px 0px 0px #53fda5,
      0px 4px 5.3px 0px rgba(0, 0, 0, 0.76);
  }

  .toast-connection-weak {
    box-shadow:
      0px 1px 0px 0px #f2994a,
      0px 4px 5.3px 0px rgba(0, 0, 0, 0.76);
  }

  .Toastify__progress-bar--error,
  .Toastify__progress-bar--animated {
    height: 2px;
    background: #7ea2df;
  }

  .Toastify__toast-body {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .Toastify__close-button > svg {
    fill: #7ea2df;
  }

  .Toastify__toast--success {
    background: #25428c;
    color: #7ea2df;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 37px;
    padding-left: 18px;
    box-shadow:
      0px 1px 0px 0px #53fda5,
      0px 4px 5.3px 0px rgba(0, 0, 0, 0.76);
  }

  .Toastify__toast--error {
    background: #25428c;
    color: #7ea2df;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 37px;
    padding-left: 18px;
    box-shadow:
      0px 1px 0px 0px #ffb4b4,
      0px 4px 5.3px 0px rgba(0, 0, 0, 0.76);
  }
`;
