// @ts-ignore
import { combineReducers } from 'redux-immutable';

import settings from '@redux/settings';
import auth from '@redux/auth';
import dashboard from '@redux/dashboard';
import image from '@redux/image';
import profile from '@redux/profile';
import withdrawal from '@redux/withdrawal';
import transaction from '@redux/transaction';
import deposit from '@redux/deposit';
import leaderboard from '@redux/leaderboard';
import missions from '@redux/missions';
import referral from '@redux/referral';
import notifications from '@redux/notifications';

export const rootReducer = combineReducers({
  auth,
  dashboard,
  settings,
  image,
  profile,
  withdrawal,
  transaction,
  deposit,
  leaderboard,
  missions,
  referral,
  notifications
});
