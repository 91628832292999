import React, { ComponentType } from 'react';

import { useIntl } from 'react-intl';
import {
  ItemsContainer,
  ItemsContainerTop,
  ItemsList,
  ItemsListItem,
  ItemsListItemLeft,
  ItemsListItemLeftRow,
  ItemsListItemRight,
} from './My.styled';

import UsdtIcon from '@icons/friends/USDT.svg';
import TonIcon from '@icons/friends/TON.svg';
import WtIcon from '@icons/friends/WT.svg';
import { balanceFormatter } from '@shared/utils/helpers/helpers';
import { IGetReferralReceivedRewardEntity } from '@common/types/referral.types';

const currencyIconMap = {
  TON: TonIcon,
  USDT: UsdtIcon,
  WT: WtIcon,
};

interface FriendsModalRewardsListSectionProps {
  data: IGetReferralReceivedRewardEntity[];
}

const FriendsModalRewardsListSection: ComponentType<
  FriendsModalRewardsListSectionProps
> = ({ data = [] }) => {
  const intl = useIntl();
  return (
    <>
      <ItemsContainer>
        <ItemsList>
          {data.map((item, index) => (
            <ItemsListItem key={index}>
              <ItemsListItemLeft>
                <ItemsListItemLeftRow>
                  <span>{balanceFormatter(item.amount)}</span>
                  <img
                    src={
                      currencyIconMap[
                        item.currencyCode as 'TON' | 'USDT' | 'WT'
                      ]
                    }
                    alt=""
                  />
                  <span>{item.currencyCode}</span>
                </ItemsListItemLeftRow>
              </ItemsListItemLeft>
              <ItemsListItemRight>
                <span>{item.createdAt}</span>
              </ItemsListItemRight>
            </ItemsListItem>
          ))}
        </ItemsList>
      </ItemsContainer>
    </>
  );
};

export default FriendsModalRewardsListSection;
