import styled from 'styled-components';
export const FriendsPageContent = styled.div`
  width: 100%;
  padding: 20px 10px 0px 10px;
  background: linear-gradient(0deg, #112351 0%, #151d38 47%);

  margin-bottom: auto;
`;

export const FriendsPageContentTop = styled.div`
  display: flex;
  gap: 10px;
`;

export const FriendsPageContentTopCol = styled.div`
  flex: 1;
  &:nth-child(1) {
  }
  &:nth-child(2) {
  }
`;

export const FriendsPageContentTopColReferrals = styled.div`
  border-radius: 11px;
  background: #13132b;
  box-shadow: 0px 1px 0px 0px #1d4595;
  padding: 9px 13px;

  display: flex;
  align-items: center;
  gap: 5px;

  span {
    &:nth-child(1) {
      color: #7ea2df;

      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: 375px) {
        font-size: 10px;
      }
    }
    &:nth-child(2) {
      color: #8aaeec;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      margin-left: auto;

      @media (max-width: 375px) {
        font-size: 14px;
      }
    }
  }

  img {
    margin-left: auto;
    width: 23px;
    height: 15px;
  }
`;
export const FriendsPageContentTopColfriendsListBtn = styled.button`
  border-radius: 9px;
  background: #123f99;
  padding: 10px 15px;
  cursor: pointer;
  width: 100%;

  span {
    color: #8aaeec;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    white-space: nowrap;

    @media (max-width: 375px) {
      font-size: 14px;
    }
  }

  transition: 0.3s background-color ease-in-out;

  &:hover {
    background-color: #0c378e;
  }

  &:disabled {
    opacity: 0.6;

    cursor: default;

    &:hover {
      background-color: #123f99;
    }
  }
`;
