import styled, { keyframes } from 'styled-components/macro';

// Default images
import DEFAULT_2 from '@assets/gif/coin_anim/hourglass/1.png';
import DEFAULT_3 from '@assets/gif/coin_anim/hourglass/2.png';
import DEFAULT_1 from '@assets/gif/coin_anim/hourglass/3.png';
import DEFAULT_4 from '@assets/gif/coin_anim/hourglass/4.png';
import DEFAULT_5 from '@assets/gif/coin_anim/hourglass/5.png';
import DEFAULT_6 from '@assets/gif/coin_anim/hourglass/6.png';
import DEFAULT_7 from '@assets/gif/coin_anim/hourglass/7.png';
import DEFAULT_8 from '@assets/gif/coin_anim/hourglass/8.png';
import DEFAULT_9 from '@assets/gif/coin_anim/hourglass/9.png';
import DEFAULT_10 from '@assets/gif/coin_anim/hourglass/10.png';
import DEFAULT_11 from '@assets/gif/coin_anim/hourglass/11.png';
import DEFAULT_12 from '@assets/gif/coin_anim/hourglass/12.png';

// Retina images
import RETINA_1 from '@assets/gif/coin_anim/hourglass/1.png';
import RETINA_2 from '@assets/gif/coin_anim/hourglass/2.png';
import RETINA_3 from '@assets/gif/coin_anim/hourglass/3.png';
import RETINA_4 from '@assets/gif/coin_anim/hourglass/4.png';
import RETINA_5 from '@assets/gif/coin_anim/hourglass/5.png';
import RETINA_6 from '@assets/gif/coin_anim/hourglass/6.png';
import RETINA_7 from '@assets/gif/coin_anim/hourglass/7.png';
import RETINA_8 from '@assets/gif/coin_anim/hourglass/8.png';
import RETINA_9 from '@assets/gif/coin_anim/hourglass/9.png';
import RETINA_10 from '@assets/gif/coin_anim/hourglass/10.png';
import RETINA_11 from '@assets/gif/coin_anim/hourglass/11.png';
import RETINA_12 from '@assets/gif/coin_anim/hourglass/12.png';

export const DEFAULT_IMAGES = [
  DEFAULT_1,
  DEFAULT_2,
  DEFAULT_3,
  DEFAULT_4,
  DEFAULT_5,
  DEFAULT_6,
  DEFAULT_7,
  DEFAULT_8,
  DEFAULT_9,
  DEFAULT_10,
  DEFAULT_11,
  DEFAULT_12,
];

export const RETINA_IMAGES = [
  RETINA_1,
  RETINA_2,
  RETINA_3,
  RETINA_4,
  RETINA_5,
  RETINA_6,
  RETINA_7,
  RETINA_8,
  RETINA_9,
  RETINA_10,
  RETINA_11,
  RETINA_12,
];

if (DEFAULT_IMAGES.length !== RETINA_IMAGES.length) {
  throw new Error('Default and retina images number must be equal');
}

const FRAMES_TOTAL = DEFAULT_IMAGES.length;

const ANIMATION_STEP_MS = 150;
const ANIMATION_DURATION_MS = FRAMES_TOTAL * ANIMATION_STEP_MS;

const PERCENT_STEP = 100 / FRAMES_TOTAL;

const coinAnimation = keyframes`
  ${DEFAULT_IMAGES.reduce((acc, curr, index) => {
    const percentage = PERCENT_STEP * index;

    const defaultImg = curr;
    const retinaImg = RETINA_IMAGES[index];

    acc += `
      ${percentage}% {
        background-image: url(${defaultImg});
        background-image: -webkit-image-set(
          url(${defaultImg}) 1x,
          url(${retinaImg}) 2x
        );
        background-image: image-set(
          url(${defaultImg}) 1x,
          url(${retinaImg}) 2x
        );
      }
    `;

    return acc;
  }, '')}
`;

export const Coin = styled.div`
  width: 100%;
  height: 100%;
  background: no-repeat center center;
  background-size: contain;
  animation: ${coinAnimation} ${ANIMATION_DURATION_MS}ms steps(1) infinite;
`;
