import React, { useState } from 'react';
import { ComponentType } from 'react';
import {
  FriendsPageContent,
  FriendsPageContentTop,
  FriendsPageContentTopCol,
  FriendsPageContentTopColReferrals,
  FriendsPageContentTopColfriendsListBtn,
} from './My.styled';

import FriendsIcon from '@icons/friends/friends.svg';

import FriendsPageFriendsModal from './components/modals/FriendsPageFriendsModal';
import FriendsPageContentRewardsSection from './components/FriendsPageContentRewardsSection';
import { createStructuredSelector } from 'reselect';
import { selectProfileInfo } from '@redux/profile/selectors';
import { connect } from 'react-redux';
import { IGetProfileResponseModel } from '@common/types/profile.types';
import { selectFriendsListLoading } from '@redux/referral/selectors';
import { useIntl } from 'react-intl';

interface FriendsPageContentProps {
  profileInfo: null | IGetProfileResponseModel;
  friendsListIsLoading: boolean;
}

const FriendsPageContentSection: ComponentType<FriendsPageContentProps> = ({
  profileInfo,
  friendsListIsLoading,
}) => {
  const intl = useIntl();
  const [isOpenFriendsModal, setIsOpenFriendsModal] = useState<boolean>(false);

  const handleOpenFriendsModal = () => {
    setIsOpenFriendsModal(true);
  };

  return (
    <>
      <FriendsPageContent>
        <FriendsPageContentTop>
          <FriendsPageContentTopCol>
            <FriendsPageContentTopColReferrals>
              <span>{intl.formatMessage({ id: 'friendsPage.referrals' })}</span>
              <span>{profileInfo?.user.referalCount ?? 0}</span>
              <img src={FriendsIcon} alt="" />
            </FriendsPageContentTopColReferrals>
          </FriendsPageContentTopCol>
          <FriendsPageContentTopCol>
            <FriendsPageContentTopColfriendsListBtn
              onClick={handleOpenFriendsModal}
            >
              <span>
                {intl.formatMessage({ id: 'friendsPage.friendsList' })}
              </span>
            </FriendsPageContentTopColfriendsListBtn>
          </FriendsPageContentTopCol>
        </FriendsPageContentTop>

        <FriendsPageContentRewardsSection />
      </FriendsPageContent>

      {isOpenFriendsModal ? (
        <FriendsPageFriendsModal
          isOpen={isOpenFriendsModal}
          onClose={() => {
            setIsOpenFriendsModal(false);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  profileInfo: selectProfileInfo,
  friendsListIsLoading: selectFriendsListLoading,
});

export default connect(mapStateToProps)(FriendsPageContentSection);
