import { IReferralClaimRewardRequestDto } from '@common/types/referral.types';

export const getFriendsListApi = ({
  page = 1,
  limit = 10,
}: {
  page: number;
  limit: number;
}) => ({
  url: '/main/referral/friends-list',
  params: {
    page,
    limit,
  },
  method: 'GET',
  clientCache: true,
});

export const postReferralClaimRewardApi = ({
  currencyCode,
}: IReferralClaimRewardRequestDto) => ({
  url: `/main/referral/claim-reward/${currencyCode}`,
  method: 'POST',
});

export const getReferralReceivedRewardsApi = ({
  page = 1,
  limit = 10,
  currencyCode = 'WT',
}: {
  page: number;
  limit: number;
  currencyCode: string;
}) => ({
  url: '/main/referral/received-rewards',
  params: {
    page,
    limit,
    currencyCode,
  },
  method: 'GET',
  clientCache: true,
});
