import { Dispatch } from 'redux';
import { referralActionTypes } from './constants';
import { showErrorToast } from '@constants/request.constants';
import get from 'lodash/get';
import http from '@api/http';
import {
  getFriendsListApi,
  getReferralReceivedRewardsApi,
  postReferralClaimRewardApi,
} from '@api/referral';
import {
  IGetFriendsResponseModel,
  IGetReferralReceivedRewardListReponseModel,
  IGetReferralReceivedRewardRequestDto,
  IReferralClaimRewardRequestDto,
  IReferralClaimRewardResponse,
} from '@common/types/referral.types';

export const referralActions = {
  // Friends list
  getFriendsListAction: () => ({
    type: referralActionTypes.GET_FRIENDS_LIST,
  }),
  getFriendsListSuccessAction: (payload: IGetFriendsResponseModel) => ({
    type: referralActionTypes.GET_FRIENDS_LIST_SUCCESS,
    payload,
  }),
  getFriendsListFailAction: (payload: any) => ({
    type: referralActionTypes.GET_FRIENDS_LIST_FAIL,
    payload,
  }),

  // Claim Reward
  postClaimRewardAction: () => ({
    type: referralActionTypes.POST_CLAIM_REWARD,
  }),
  postClaimRewardSuccessAction: (payload: IReferralClaimRewardResponse) => ({
    type: referralActionTypes.POST_CLAIM_REWARD_SUCCESS,
    payload,
  }),
  postClaimRewardFailAction: (payload: any) => ({
    type: referralActionTypes.POST_CLAIM_REWARD_FAIL,
    payload,
  }),

  // Received rewards list
  getReferralReceivedRewardAction: () => ({
    type: referralActionTypes.GET_REFERRAL_RECEIVED_REWARD,
  }),
  getReferralReceivedRewardSuccessAction: (
    payload: IGetReferralReceivedRewardListReponseModel
  ) => ({
    type: referralActionTypes.GET_REFERRAL_RECEIVED_REWARD_SUCCESS,
    payload,
  }),
  getReferralReceivedRewardFailAction: (payload: any) => ({
    type: referralActionTypes.GET_REFERRAL_RECEIVED_REWARD_FAIL,
    payload,
  }),
};

export const getFriendsList =
  ({
    intl,
    page = 1,
    limit = 10,
  }: {
    intl: any;
    page: number;
    limit: number;
  }) =>
  async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(referralActions.getFriendsListAction());
    try {
      const response = await send(getFriendsListApi({ page, limit }));
      const data = response.data;

      dispatch(referralActions.getFriendsListSuccessAction(data));
      return data;
    } catch (error) {
      const errorResponse = get(error, 'response.data.message', '');

      showErrorToast(errorResponse, intl);
      dispatch(referralActions.getFriendsListFailAction(errorResponse));
    }
  };

export const postReferralClaimReward =
  ({ currencyCode, intl }: IReferralClaimRewardRequestDto & { intl: any }) =>
  async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(referralActions.postClaimRewardAction());
    try {
      const response = await send(postReferralClaimRewardApi({ currencyCode }));
      const data = response.data;

      dispatch(referralActions.postClaimRewardSuccessAction(data));
      return data;
    } catch (error) {
      const errorResponse = get(error, 'response.data.message', '');

      showErrorToast(errorResponse, intl);
      dispatch(referralActions.postClaimRewardFailAction(errorResponse));
    }
  };

export const getReferralReceivedReward =
  ({
    intl,
    page = 1,
    limit = 10,
    currencyCode = 'WT',
  }: IGetReferralReceivedRewardRequestDto & { intl: any }) =>
  async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(referralActions.getReferralReceivedRewardAction());
    try {
      const response = await send(
        getReferralReceivedRewardsApi({ page, limit, currencyCode })
      );
      const data = response.data;

      dispatch(referralActions.getReferralReceivedRewardSuccessAction(data));
      return data;
    } catch (error) {
      const errorResponse = get(error, 'response.data.message', '');

      showErrorToast(errorResponse, intl);
      dispatch(
        referralActions.getReferralReceivedRewardFailAction(errorResponse)
      );
    }
  };
