import React, { ComponentType } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import {
  PlayersContainer,
  PlayersContainerTop,
  PlayersList,
  PlayersListItem,
  PlayersListItemLeft,
  PlayersListItemRight,
  PlayersListItemRightRow,
} from './My.styled';

import UsdtIcon from '@icons/svg/USDT.svg';
import TonIcon from '@icons/svg/TON.svg';
import WtIcon from '@icons/svg/WT.svg';
import { balanceFormatter } from '@shared/utils/helpers/helpers';
import { IGetFriendsListEntity } from '@common/types/referral.types';

const currencyIconMap = {
  TON: TonIcon,
  USDT: UsdtIcon,
  WT: WtIcon,
};

interface FriendsModalTurnoverPlayersSectionProps {
  data: IGetFriendsListEntity[];
}

const FriendsModalTurnoverPlayersSection: ComponentType<
  FriendsModalTurnoverPlayersSectionProps
> = ({ data = [] }) => {
  const intl = useIntl();
  return (
    <>
      <PlayersContainer>
        <PlayersContainerTop>
          <span>
            <FormattedMessage id="friendsPage.friendsModal.playerName" />
          </span>
          <span>
            <FormattedMessage id="friendsPage.friendsModal.turnover" />
          </span>
        </PlayersContainerTop>
        <PlayersList>
          {data.map((item, index) => (
            <PlayersListItem key={index}>
              <PlayersListItemLeft>
                <span>{item.playerName}</span>
              </PlayersListItemLeft>
              <PlayersListItemRight>
                {item.balance.map((balanceItem: any, index: number) => (
                  <PlayersListItemRightRow key={index}>
                    <span>{balanceFormatter(Number(balanceItem.amount))} </span>
                    <img
                      src={
                        currencyIconMap[
                          balanceItem.currencyCode as 'TON' | 'USDT' | 'WT'
                        ]
                      }
                      alt=""
                    />
                    <span>{balanceItem.currencyCode}</span>
                  </PlayersListItemRightRow>
                ))}
              </PlayersListItemRight>
            </PlayersListItem>
          ))}
        </PlayersList>
      </PlayersContainer>
    </>
  );
};

export default FriendsModalTurnoverPlayersSection;
