import { Dispatch } from 'redux';
import { profileActionTypes } from './constants';
import { IGetProfileResponseModel } from '@common/types/profile.types';
import { getProfileApi, updateNickNameApi } from '@api/profile';
import { toast } from 'react-toastify';
import {
  serverErrorsMessages,
  showErrorToast,
} from '@constants/request.constants';
import get from 'lodash/get';
import omit from 'lodash/omit';
import http from '@api/http';
import { depositActions } from '@redux/deposit/action';

export const profileActions = {
  // GET MAIN INFO
  getProfileInfoAction: () => ({
    type: profileActionTypes.GET_PROFILE_INFO,
  }),
  getProfileInfoSuccessAction: (payload: IGetProfileResponseModel) => ({
    type: profileActionTypes.GET_PROFILE_INFO_SUCCESS,
    payload,
  }),
  getProfileInfoFailAction: (payload: any) => ({
    type: profileActionTypes.GET_PROFILE_INFO_FAIL,
    payload,
  }),

  setSelectedSortListOfDuels: (payload: any) => ({
    type: profileActionTypes.SET_SELECTED_SORT_LIST_OF_DUELS,
    payload,
  }),

  // UPDATE NICKNAME
  updateNickNameAction: () => ({
    type: profileActionTypes.UPDATE_NICKNAME,
  }),
  updateNickNameSuccessAction: () => ({
    type: profileActionTypes.UPDATE_NICKNAME_SUCCESS,
  }),
  updateNickNameFailAction: (payload: any) => ({
    type: profileActionTypes.UPDATE_NICKNAME_FAIL,
    payload,
  }),
};

export const setSelectedSOrtListOfDuels =
  (params: any) => async (dispatch: Dispatch) => {
    dispatch(profileActions.setSelectedSortListOfDuels(params));
  };

export const getProfileInfo = (params: any) => async (dispatch: Dispatch) => {
  const send = http(dispatch);

  try {
    dispatch(profileActions.getProfileInfoAction());
    const response = await send(getProfileApi(params.page, params.gameId));

    dispatch(profileActions.getProfileInfoSuccessAction(response.data));
  } catch (error) {
    // @ts-ignore
    const errorResponse = get(error, 'response.data.message', '');

    dispatch(profileActions.getProfileInfoFailAction({ error }));
  }
};

export const updateNickName = (params: any) => async (dispatch: Dispatch) => {
  const send = http(dispatch);

  try {
    dispatch(profileActions.updateNickNameAction());
    await send(updateNickNameApi({ nickName: params.nickName }));

    dispatch(profileActions.updateNickNameSuccessAction());

    // Обновляем данные профиля после успешного изменения
    await dispatch<any>(getProfileInfo({ intl: params.intl }));

    // Показываем уведомление об успехе
    toast.success(params.intl.formatMessage({ id: 'profile.nickNameUpdated' }));

    return true;
  } catch (error) {
    const errorResponse = get(error, 'response.data.message', '');
    dispatch(profileActions.updateNickNameFailAction({ error }));

    // Показываем уведомление об ошибке
    toast.error(params.intl.formatMessage({ id: 'errors.updateFailed' }));

    return false;
  }
};

export const setSoundAction = (payload: boolean) => ({
  type: profileActionTypes.SOUND_SWITCHER,
  payload,
});
