import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { referralActionTypes } from './constants';

interface ReferralState {
  friends: {
    isLoading: boolean;
    error: any;
    data: null;
  };
  receivedRewards: {
    isLoading: boolean;
    error: any;
    data: null;
  };
  claimReward: {
    isLoading: boolean;
    error: any;
  };
}

const initialState: ReferralState = {
  friends: {
    isLoading: true,
    error: null,
    data: null,
  },
  receivedRewards: {
    isLoading: false,
    error: null,
    data: null,
  },
  claimReward: {
    isLoading: false,
    error: null,
  },
};

// @ts-ignore
const referralReducer: Reducer<Map<string, any>, referralActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case referralActionTypes.GET_FRIENDS_LIST:
      return state.merge({
        friends: {
          isLoading: true,
          error: null,
        },
      });

    case referralActionTypes.GET_FRIENDS_LIST_SUCCESS:
      return state.merge({
        friends: {
          isLoading: false,
          data: payload?.data,
        },
      });

    case referralActionTypes.GET_FRIENDS_LIST_FAIL:
      return state.merge({
        friends: {
          isLoading: false,
          error: payload,
        },
      });

    case referralActionTypes.POST_CLAIM_REWARD:
      return state.merge({
        claimReward: {
          isLoading: true,
          error: null,
        },
      });

    case referralActionTypes.POST_CLAIM_REWARD_SUCCESS:
      return state.merge({
        claimReward: {
          isLoading: false,
        },
      });

    case referralActionTypes.POST_CLAIM_REWARD_FAIL:
      return state.merge({
        claimReward: {
          isLoading: false,
          error: payload,
        },
      });

    case referralActionTypes.GET_REFERRAL_RECEIVED_REWARD:
      return state.merge({
        receivedRewards: {
          isLoading: true,
          data: null,
          error: null,
        },
      });

    case referralActionTypes.GET_REFERRAL_RECEIVED_REWARD_SUCCESS:
      return state.merge({
        receivedRewards: {
          isLoading: false,
          data: payload,
        },
      });

    case referralActionTypes.GET_REFERRAL_RECEIVED_REWARD_FAIL:
      return state.merge({
        receivedRewards: {
          isLoading: false,
          error: payload,
        },
      });

    case referralActionTypes.CLEAR_ERROR:
      return state.merge({
        error: null,
      });

    default:
      return state;
  }
};

export default referralReducer;
