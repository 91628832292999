import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

import { DashboardNavWrapper, NavItem, NavText } from './My.styled';

import FriendsIcon from '@icons/home/nav/friends.svg';
import MissionsIcon from '@icons/home/nav/missions.svg';
import HomeIcon from '@icons/home/nav/home.svg';
import LeaderboardIcon from '@icons/home/nav/leaderboard.svg';
import ProfileIcon from '@icons/home/nav/profile.svg';

import ReactGA from 'react-ga4';
import { GA_CATEGORIES } from '@services/config';
import { gaTelegram, gaUserSource, sendGAEvent } from '@utils/helpers/helpers';
import { createStructuredSelector } from 'reselect';
import { getProfileInfo } from '@redux/profile/action';
import { connect } from 'react-redux';
import { selectProfileInfo } from '@redux/profile/selectors';
import { IGetProfileResponseModel } from '@common/types/profile.types';

interface DashboardNavProps {
  handleGetProfileInfo: (params: any) => Promise<void>;
  activeItem?: null | string;
  profileInfo: null | IGetProfileResponseModel;
}

const navList = [
  {
    name: 'friends',
    gaEvent: 'friends_page_click',
    link: '/friends',
    // link: "/market",
    active: false,
  },
  {
    name: 'missions',
    gaEvent: 'missions_page_click',
    link: '/missions',
    // link: "/tournament",
    active: false,
  },
  {
    name: 'home',
    gaEvent: 'home_page_click',
    link: '/dashboard',
    active: false,
  },
  {
    name: 'leaderboard',
    gaEvent: 'leaderboard_page_click',
    // link: '/leaderboard-coming',
    link: '/leaderboard',
    active: false,
  },
  {
    name: 'profile',
    gaEvent: 'dashboard_page_click',
    link: '/profile',
    active: false,
  },
];

const DashboardNav: ComponentType<DashboardNavProps> = ({
  handleGetProfileInfo,
  activeItem = null,
  profileInfo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isShowMarker, setIsShowMarker] = useState<boolean>(false);

  const getIcon = (item: any) => {
    switch (item.name) {
      case 'friends':
        return (
          // <FriendsIcon
          //   active={activeItem ? activeItem === item.name : item.active}
          // />
          <img src={FriendsIcon} alt="" />
        );
      case 'missions':
        return <img src={MissionsIcon} alt="" />;
      case 'home':
        return <img src={HomeIcon} alt="" />;
      case 'leaderboard':
        return <img src={LeaderboardIcon} alt="" />;
      case 'profile':
        return <img src={ProfileIcon} alt="" />;
      default:
        return <></>;
    }
  };

  const updatedNavList = useMemo(
    () =>
      navList.map((item) => ({
        ...item,
        active: location.pathname === item.link,
      })),
    [location.pathname]
  );

  const handleItemClick = (item: any) => {
    if (!item.active) {
      sendGAEvent(item.gaEvent + gaTelegram(), {
        category: GA_CATEGORIES.USER,
        user_source: gaUserSource(),
      });
      navigate(item.link);
    }
  };

  useEffect(() => {
    if (!profileInfo) return;

    const isClaim = profileInfo.user.referralRewards.some(
      (item) => item.canClaim
    );

    if (!isClaim) {
      setIsShowMarker(false);
      return;
    }

    setIsShowMarker(true);
  }, [profileInfo, profileInfo?.user.referralRewards]);

  return (
    <DashboardNavWrapper>
      {updatedNavList.map((item: any, index: number) => (
        <NavItem
          active={item.active}
          onClick={() => handleItemClick(item)}
          key={index}
        >
          {isShowMarker && 'friends' === item.name && <span></span>}
          {getIcon(item)}
          <NavText active={activeItem ? activeItem === item.name : item.active}>
            <FormattedMessage id={'dashboard.' + item.name} />
          </NavText>
        </NavItem>
      ))}
    </DashboardNavWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  profileInfo: selectProfileInfo,
});

const mapDispatchToProps = {
  handleGetProfileInfo: getProfileInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNav);
