import { useState } from 'react';

export function useScrollWithShadow() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const onScrollHandler = (event: any) => {
    setScrollTop(event.target.scrollTop);
    setScrollHeight(event.target.scrollHeight);
    setClientHeight(event.target.clientHeight);
  };

  const isTopShadow = scrollTop > 0;
  const isBottomShadow = scrollTop + clientHeight < scrollHeight - 1;

  return { isTopShadow, isBottomShadow, onScrollHandler };
}
