import * as React from 'react';

function CloseIconModalRedesigned(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M14.3548 0.76746C15.378 -0.25582 17.0371 -0.25582 18.0604 0.76746C19.0837 1.79074 19.0837 3.44981 18.0604 4.47309L4.47309 18.0604C3.44981 19.0837 1.79074 19.0837 0.76746 18.0604C-0.25582 17.0371 -0.25582 15.378 0.76746 14.3548L14.3548 0.76746Z"
        fill="#7EA2DF"
      />
      <path
        d="M4.47445 0.76746C3.45117 -0.25582 1.7921 -0.25582 0.76882 0.76746C-0.25446 1.79074 -0.254461 3.44981 0.768819 4.47309L14.3561 18.0604C15.3794 19.0837 17.0385 19.0837 18.0617 18.0604C19.085 17.0371 19.085 15.378 18.0617 14.3548L4.47445 0.76746Z"
        fill="#7EA2DF"
      />
    </svg>
  );
}
export default CloseIconModalRedesigned;
