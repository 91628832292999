import styled from 'styled-components';

export const FriendsPageContentFriends = styled.div`
  padding: 10px 0 10px 0;
`;
export const FriendsPageContentFriendsTitle = styled.h5`
  color: #7ea2df;

  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;
export const FriendsPageContentFriendsList = styled.div``;
export const FriendsPageContentFriendsItem = styled.div<{ isLoading: boolean }>`
  flex-shrink: 0;

  display: flex;
  border-radius: 7px;
  background: #25428c;
  padding: 8px;
  overflow: hidden;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 60px;

    background-color: #7296d2;
  }

  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  opacity: ${({ isLoading }) => (isLoading ? 0.3 : 1)};
`;
export const FriendsPageContentFriendsItemColReceived = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  width: 50%;
`;

export const FriendsPageContentFriendsItemColReceivedLeft = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 37px;
    height: 37px;
    display: block;
    margin-bottom: 2px;
  }

  span {
    color: #7296d2;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (max-width: 350px) {
    img {
      width: 27px;
      height: 27px;
    }

    span {
      font-size: 11px;
    }
  }
`;
export const FriendsPageContentFriendsItemColReceivedCenter = styled.div`
  p {
    color: #7296d2;

    text-align: center;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  span {
    color: #8aaeec;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (max-width: 350px) {
    p {
      font-size: 8px;
    }

    span {
      font-size: 14px;
    }
  }
`;

export const FriendsPageContentFriendsItemColReceivedRight = styled.button`
  cursor: pointer;
  width: 28px;
  height: 28px;
  min-width: 28px;
  max-width: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  border-radius: 7px;

  background: #15217a;
  color: #7ea2df;
  text-align: right;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  transition: 0.3s background-color ease-in-out;

  &:hover:not(:disabled) {
    background-color: #243298;
  }

  &:disabled {
    cursor: default;
  }
`;

export const FriendsPageContentFriendsItemColEarned = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
`;

export const FriendsPageContentFriendsItemColEarnedLeft = styled.div`
  margin-right: 5px;
  p {
    color: #06a394;

    text-align: center;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  span {
    color: #53fda5;

    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (max-width: 350px) {
    p {
      font-size: 8px;
    }

    span {
      font-size: 14px;
    }
  }
`;
export const FriendsPageContentFriendsItemColEarnedButton = styled.button<{
  isDisabled?: boolean;
}>`
  border-radius: 7px;
  background: ${({ isDisabled }) => (isDisabled ? '#105c6a' : '#06a394')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'pointer' : 'pointer')};
  padding: 15px 11px;

  span {
    display: block;
    color: ${({ isDisabled }) => (isDisabled ? '#3D9C7E' : '#53fda5')};
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    transition: 0.3s color ease-in-out;
  }

  transition: 0.3s background-color ease-in-out;

  &:hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? '#105c6a' : '#0dc5a6'};
  }

  @media (max-width: 376px) {
    padding: 8px 10px;

    span {
      font-size: 16px;
    }
  }
`;
