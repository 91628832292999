import ModalRedesigned from '@components/Attoms/ModalRedesigned';
import React, { ComponentType, useEffect } from 'react';

import { FriendsModalContent, FriendsModalNoRecordText } from './My.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import FriendsModalRewardsListSection from './components/FriendsModalRewardsListSection';
import { IGetReferralReceivedRewardEntity } from '@common/types/referral.types';
import InfiniteScroll from '@components/Attoms/InfiniteScroll';
import LoadingSpin from '@components/Attoms/ModalRedesigned/components/LoadingSpin';

interface FriendsPageRewardsModalProps {
  isOpen: boolean;
  onClose: () => void;
  loadMore: () => void;
  data: IGetReferralReceivedRewardEntity[];
  page: number;
  lastPage: number;
  isLoading: boolean;
  isScrollShadow?: boolean;
}

const FriendsPageRewardsModal: ComponentType<FriendsPageRewardsModalProps> = ({
  isOpen,
  onClose,
  loadMore,
  data,
  page,
  lastPage,
  isLoading,
  isScrollShadow = false,
}) => {
  const intl = useIntl();

  return (
    <ModalRedesigned
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({
        id: 'friendsPage.friendsRewardsModal.title',
      })}
      isLoading={isLoading && data.length <= 0}
      isScrollShadow={isScrollShadow}
    >
      <FriendsModalContent>
        {data && data.length > 0 && (
          <>
            <FriendsModalRewardsListSection data={data} />
            {isLoading && <LoadingSpin></LoadingSpin>}
            {data && page < lastPage && (
              <InfiniteScroll loadMore={loadMore} isLoading={isLoading} />
            )}
          </>
        )}

        {!isLoading && (data?.length ?? 0) <= 0 && (
          <FriendsModalNoRecordText>
            <FormattedMessage
              id="friendsPage.friendsRewardsModal.noDataText"
              values={{
                br: <br />,
              }}
            />
          </FriendsModalNoRecordText>
        )}
      </FriendsModalContent>
    </ModalRedesigned>
  );
};

export default FriendsPageRewardsModal;
