import ModalRedesigned from '@components/Attoms/ModalRedesigned';
import React, { ComponentType, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FriendsPageInfoModalContent } from '../../../My.styled';

interface FriendsPageInfoToYouModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FriendsPageInfoToYouModal: ComponentType<
  FriendsPageInfoToYouModalProps
> = ({ isOpen, onClose }) => {
  return (
    <ModalRedesigned isOpen={isOpen} onClose={onClose} isScrollShadow={false}>
      <FriendsPageInfoModalContent>
        <FormattedMessage
          id="friendsPage.info.item3.content"
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            ol: (chunks) => <ol>{chunks}</ol>,
            li: (chunks) => <li>{chunks}</li>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      </FriendsPageInfoModalContent>
    </ModalRedesigned>
  );
};

export default FriendsPageInfoToYouModal;
