import React, { useEffect, useState } from 'react';
import { ComponentType } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CopyIcon from '@icons/friends/copy.svg';
import {
  FriendsPageInfoButtons,
  FriendsPageInfoButtonInvite,
  FriendsPageInfoButtonCopy,
} from './My.styled';
import { toast } from 'react-toastify';
import { IGetProfileResponseModel } from '@common/types/profile.types';
import { createStructuredSelector } from 'reselect';
import { selectIsLoading, selectProfileInfo } from '@redux/profile/selectors';
import { getProfileInfo } from '@redux/profile/action';
import { connect } from 'react-redux';

import FriendsPageShareModal from './modals/FriendsPageShareModal';
import { getValueFromLocalStorage } from '@services/tokenStorageService';
import { AppConfig } from '@services/config';

interface FriendsPageInfoButtonsSectionProps {
  profileInfo: null | IGetProfileResponseModel;
  profileIsLoading: boolean;
}

const FriendsPageInfoButtonsSection: ComponentType<
  FriendsPageInfoButtonsSectionProps
> = ({ profileInfo, profileIsLoading }) => {
  const intl = useIntl();

  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const isTelegram = getValueFromLocalStorage('isTelegram');

  const handleShare = () => {
    setIsOpenShareModal(true);
  };

  const handleCopy = () => {
    if (!profileInfo?.user.referalLink) return;

    const referralLink =
      isTelegram === 'true'
        ? `${AppConfig.telegramAppUrl}/app?startapp=${profileInfo.user.referalLink}`
        : `${location.host}/authorization?referral=${profileInfo.user.referalLink}`;

    navigator.clipboard.writeText(referralLink).then(() => {
      toast.success(intl.formatMessage({ id: 'friendsPage.copyText' }));
    });
  };

  return (
    <>
      <FriendsPageInfoButtons>
        <FriendsPageInfoButtonInvite
          onClick={handleShare}
          disabled={profileIsLoading}
        >
          <span
            data-text={intl.formatMessage({
              id: 'friendsPage.info.buttonInvite',
            })}
          >
            <FormattedMessage id="friendsPage.info.buttonInvite" />
          </span>
        </FriendsPageInfoButtonInvite>
        <FriendsPageInfoButtonCopy
          onClick={handleCopy}
          disabled={profileIsLoading}
        >
          <img src={CopyIcon} alt="" />
        </FriendsPageInfoButtonCopy>
      </FriendsPageInfoButtons>

      {isOpenShareModal && (
        <FriendsPageShareModal
          isOpen={isOpenShareModal}
          onClose={() => setIsOpenShareModal(false)}
          isLoading={false}
          shareUrl={profileInfo?.user.referalLink ?? ''}
        ></FriendsPageShareModal>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  profileInfo: selectProfileInfo,
  profileIsLoading: selectIsLoading,
});
const mapDispatchToProps = {
  handleGetProfileInfo: getProfileInfo,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsPageInfoButtonsSection);
