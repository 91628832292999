import React, { useEffect, useState } from 'react';
import { ComponentType } from 'react';

import {
  FriendsPageContentFriends,
  FriendsPageContentFriendsTitle,
  FriendsPageContentFriendsList,
  FriendsPageContentFriendsItem,
  FriendsPageContentFriendsItemColReceived,
  FriendsPageContentFriendsItemColReceivedLeft,
  FriendsPageContentFriendsItemColReceivedCenter,
  FriendsPageContentFriendsItemColReceivedRight,
  FriendsPageContentFriendsItemColEarned,
  FriendsPageContentFriendsItemColEarnedLeft,
  FriendsPageContentFriendsItemColEarnedButton,
} from './My.styled';

import WtIcon from '@icons/friends/WT.svg';
import UsdtIcon from '@icons/friends/USDT.svg';
import TonIcon from '@icons/friends/TON.svg';
import FriendsPageRewardsModal from './modals/FriendsPageRewardsModal';
import { selectIsLoading, selectProfileInfo } from '@redux/profile/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { IGetProfileResponseModel } from '@common/types/profile.types';
import {
  getReferralReceivedReward,
  postReferralClaimReward,
} from '@redux/referral/action';
import {
  selectReceivedRewards,
  selectReceivedRewardsLoading,
  selectReferralIsLoadingClaim,
} from '@redux/referral/selectors';
import {
  IGetReferralReceivedRewardEntity,
  IGetReferralReceivedRewardListModel,
  IGetReferralReceivedRewardRequestDto,
  IReferralClaimRewardRequestDto,
} from '@common/types/referral.types';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { balanceFormatter } from '@shared/utils/helpers/helpers';

const currencyIconMap = {
  TON: TonIcon,
  USDT: UsdtIcon,
  WT: WtIcon,
};

const currencyKeys: Array<'TON' | 'USDT' | 'WT'> = ['TON', 'USDT', 'WT'];

interface FriendsPageContentFriendsProps {
  profileInfo: IGetProfileResponseModel;
  profileIsLoading: boolean;
  handlePostReferralClaimReward: (
    payload: IReferralClaimRewardRequestDto & { intl: any }
  ) => Promise<void>;
  referralIsLoadingClaim: boolean;
  handleGetRewards: (
    payload: IGetReferralReceivedRewardRequestDto & { intl: any }
  ) => Promise<void>;
  data: IGetReferralReceivedRewardListModel;
  isReceivedRewardsLoading: boolean;
}

const FriendsPageContentFriendsSection: ComponentType<
  FriendsPageContentFriendsProps
> = ({
  profileInfo,
  profileIsLoading,
  handlePostReferralClaimReward,
  handleGetRewards,
  data,
  isReceivedRewardsLoading,
}) => {
  const intl = useIntl();

  const [isOpenRewardsModal, setIsOpenRewardsModal] = useState<boolean>(false);
  const [claiming, setClaiming] = useState<string | null>(null);
  const [isLoadingRewards, setIsLoadingRewards] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rewards, setRewards] = useState<IGetReferralReceivedRewardEntity[]>(
    []
  );
  const [currencyCode, setCurrencyCode] = useState<string>('WT');

  const handleShowRewards = (selectedCurrencyCode: string) => {
    setRewards([]);
    setCurrencyCode(selectedCurrencyCode);
    setPage(1);
    setIsOpenRewardsModal(true);
  };

  const handleClaim = async ({
    currencyCode,
  }: IReferralClaimRewardRequestDto) => {
    await handlePostReferralClaimReward({ currencyCode, intl });
    toast.success(intl.formatMessage({ id: 'friendsPage.successClaimedText' }));
  };

  useEffect(() => {
    if (!isOpenRewardsModal) return;
    setIsLoadingRewards(true);
    handleGetRewards({ page, intl, limit: 50, currencyCode }).finally(() => {
      setIsLoadingRewards(false);
    });
  }, [page, handleGetRewards, intl, currencyCode, isOpenRewardsModal]);

  useEffect(() => {
    if (!data) return;
    if (data?.entities && data.page === page) {
      if (page === 1) {
        setRewards(data.entities);
      } else {
        setRewards((prev) => prev.concat(data.entities || []));
      }
    }
  }, [data, page]);

  const loadMore = () => {
    if (!isLoadingRewards && data?.lastPage && page < data.lastPage) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <FriendsPageContentFriends>
        <FriendsPageContentFriendsTitle>
          {intl.formatMessage({ id: 'friendsPage.yourRewards' })}
        </FriendsPageContentFriendsTitle>
        <FriendsPageContentFriendsList>
          {profileIsLoading || profileInfo?.user.referralRewards?.length <= 0
            ? Array.from({ length: 3 }).map((item, index) => (
                <FriendsPageContentFriendsItem
                  isLoading={profileIsLoading}
                  key={index}
                >
                  <FriendsPageContentFriendsItemColReceived>
                    <FriendsPageContentFriendsItemColReceivedLeft>
                      <img
                        src={
                          currencyIconMap[
                            currencyKeys[index] as 'TON' | 'USDT' | 'WT'
                          ]
                        }
                        alt=""
                      />
                      <span>{currencyKeys[index]}</span>
                    </FriendsPageContentFriendsItemColReceivedLeft>
                    <FriendsPageContentFriendsItemColReceivedCenter>
                      <p>
                        {intl.formatMessage({ id: 'friendsPage.received' })}
                      </p>
                      <span>0</span>
                    </FriendsPageContentFriendsItemColReceivedCenter>
                    <FriendsPageContentFriendsItemColReceivedRight
                      disabled={isLoadingRewards}
                      onClick={() => handleShowRewards(currencyKeys[index])}
                    >
                      i
                    </FriendsPageContentFriendsItemColReceivedRight>
                  </FriendsPageContentFriendsItemColReceived>
                  <FriendsPageContentFriendsItemColEarned>
                    <FriendsPageContentFriendsItemColEarnedLeft>
                      <p>{intl.formatMessage({ id: 'friendsPage.earned' })}</p>
                      <span>0</span>
                    </FriendsPageContentFriendsItemColEarnedLeft>
                    <FriendsPageContentFriendsItemColEarnedButton
                      isDisabled={true}
                    >
                      <span>
                        {intl.formatMessage({ id: 'friendsPage.claim' })}
                      </span>
                    </FriendsPageContentFriendsItemColEarnedButton>
                  </FriendsPageContentFriendsItemColEarned>
                </FriendsPageContentFriendsItem>
              ))
            : profileInfo?.user.referralRewards?.length > 0 &&
              profileInfo.user.referralRewards.map((item, index) => (
                <FriendsPageContentFriendsItem isLoading={false} key={index}>
                  <FriendsPageContentFriendsItemColReceived>
                    <FriendsPageContentFriendsItemColReceivedLeft>
                      <img
                        src={
                          currencyIconMap[
                            item.currencyCode as 'TON' | 'USDT' | 'WT'
                          ]
                        }
                        alt=""
                      />
                      <span>{item.currencyCode}</span>
                    </FriendsPageContentFriendsItemColReceivedLeft>
                    <FriendsPageContentFriendsItemColReceivedCenter>
                      <p>
                        {intl.formatMessage({ id: 'friendsPage.received' })}
                      </p>
                      <span>{balanceFormatter(Number(item.received))} </span>
                    </FriendsPageContentFriendsItemColReceivedCenter>
                    <FriendsPageContentFriendsItemColReceivedRight
                      disabled={isLoadingRewards}
                      onClick={() => {
                        handleShowRewards(item.currencyCode);
                      }}
                    >
                      i
                    </FriendsPageContentFriendsItemColReceivedRight>
                  </FriendsPageContentFriendsItemColReceived>
                  <FriendsPageContentFriendsItemColEarned>
                    <FriendsPageContentFriendsItemColEarnedLeft>
                      <p>{intl.formatMessage({ id: 'friendsPage.earned' })}</p>
                      <span>{balanceFormatter(Number(item.earned))}</span>
                    </FriendsPageContentFriendsItemColEarnedLeft>
                    <FriendsPageContentFriendsItemColEarnedButton
                      isDisabled={
                        !item.canClaim ||
                        claiming === item.currencyCode ||
                        (item.currencyCode === 'TON' &&
                          Number(item.received) < 1) ||
                        (item.currencyCode === 'USDT' &&
                          Number(item.received) < 5)
                      }
                      onClick={async () => {
                        if (
                          !item.canClaim ||
                          claiming === item.currencyCode ||
                          (item.currencyCode === 'TON' &&
                            Number(item.received) < 1) ||
                          (item.currencyCode === 'USDT' &&
                            Number(item.received) < 5)
                        ) {
                          if (
                            item.currencyCode === 'TON' &&
                            Number(item.received) < 1
                          ) {
                            toast.info(
                              intl.formatMessage({
                                id: 'friendsPage.claimTonNotice',
                              })
                            );
                          }
                          if (
                            item.currencyCode === 'USDT' &&
                            Number(item.received) < 5
                          ) {
                            toast.info(
                              intl.formatMessage({
                                id: 'friendsPage.claimUsdtNotice',
                              })
                            );
                          }
                          return;
                        }
                        setClaiming(item.currencyCode);
                        await handleClaim({ currencyCode: item.currencyCode });
                        setClaiming(null);
                      }}
                    >
                      <span>
                        {intl.formatMessage({ id: 'friendsPage.claim' })}
                      </span>
                    </FriendsPageContentFriendsItemColEarnedButton>
                  </FriendsPageContentFriendsItemColEarned>
                </FriendsPageContentFriendsItem>
              ))}
        </FriendsPageContentFriendsList>
      </FriendsPageContentFriends>

      {isOpenRewardsModal && (
        <FriendsPageRewardsModal
          isOpen={isOpenRewardsModal}
          onClose={() => {
            setIsOpenRewardsModal(false);
          }}
          loadMore={loadMore}
          data={rewards}
          page={page}
          lastPage={data?.lastPage ?? 1}
          isLoading={isReceivedRewardsLoading}
          isScrollShadow={true}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  profileInfo: selectProfileInfo,
  profileIsLoading: selectIsLoading,
  referralIsLoadingClaim: selectReferralIsLoadingClaim,
  data: selectReceivedRewards,
  isReceivedRewardsLoading: selectReceivedRewardsLoading,
});

const mapDispatchToProps = {
  handlePostReferralClaimReward: postReferralClaimReward,
  handleGetRewards: getReferralReceivedReward,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsPageContentFriendsSection);
