import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { notificationActionTypes } from './constants';

export interface INotification {
  id: number;
  user_id: number;
  title: string;
  message: string;
  windowType: string;
}

interface NotificationState {
  isLoading: boolean;
  error: null | any;
  notifications: INotification[] | null;
}

type NotificationActionTypes = {
  type: (typeof notificationActionTypes)[keyof typeof notificationActionTypes];
  payload?: any;
};

const initialState: NotificationState = {
  isLoading: false,
  error: null,
  notifications: null,
};

const notificationReducer: Reducer<
  Map<string, any>,
  NotificationActionTypes
> = (state = fromJS(initialState), { type, payload }) => {
  switch (type) {
    case notificationActionTypes.GET_NOTIFICATIONS:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case notificationActionTypes.GET_NOTIFICATIONS_SUCCESS:
      return state.merge({
        isLoading: false,
        notifications: payload,
      });

    case notificationActionTypes.GET_NOTIFICATIONS_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
      });

    case notificationActionTypes.DELETE_NOTIFICATION:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case notificationActionTypes.DELETE_NOTIFICATION_SUCCESS:
      return state.merge({
        isLoading: false,
        notifications: state
          .get('notifications')
          .filter((notification: INotification) => notification.id !== payload),
      });

    case notificationActionTypes.DELETE_NOTIFICATION_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
      });

    case notificationActionTypes.CLEAR_ERROR:
      return state.merge({
        error: null,
      });

    default:
      return state;
  }
};

export default notificationReducer;
