import styled from 'styled-components/macro';

export const FriendsModalTurnover = styled.div``;

export const FriendsModalTurnoverTitle = styled.h6`
  color: #8aaeec;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
`;
export const FriendsModalTurnoverRow = styled.div`
  border-radius: 11px;
  background: #13132b;
  box-shadow: 0px 1px 0px 0px #1d4595;

  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  padding: 4px 11px;

  @media (max-width: 375px) {
    padding: 4px 8px;
  }
`;

export const TurnoverSeparatorLine = styled.div`
  display: flex;
  justify-content: center;
`;

export const FriendsModalTurnoverRowLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`;
export const FriendsModalTurnoverRowRight = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`;

export const FriendsModalTurnoverRowAmount = styled.span`
  color: #cddaf0;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 375px) {
    font-size: 14px;
  }
`;
export const FriendsModalTurnoverRowIcon = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 5px;
  }

  span {
    color: #6080b9;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 375px) {
      font-size: 10px;
    }
  }
`;
