import ModalRedesigned from '@components/Attoms/ModalRedesigned';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { FriendsModalContent, FriendsModalNoRecordText } from './My.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import FriendsModalTurnoverSection from './components/FriendsModalTurnoverSection';
import FriendsModalTurnoverPlayersSection from './components/FriendsModalTurnoverPlayersSection';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectFriendsList } from '@redux/referral/selectors';
import { getFriendsList } from '@redux/referral/action';
import InfiniteScroll from '@components/Attoms/InfiniteScroll';
import {
  IGetFriendsListEntity,
  IGetFriendsListModel,
} from '@common/types/referral.types';
import LoadingSpin from '@components/Attoms/ModalRedesigned/components/LoadingSpin';

interface FriendsPageFriendsModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: IGetFriendsListModel;
  handleGetData: (params: any) => Promise<void>;
}

const FriendsPageFriendsModal: ComponentType<FriendsPageFriendsModalProps> = ({
  onClose,
  handleGetData,
  isOpen,
  data,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [friendsList, setFriendsList] = useState<IGetFriendsListEntity[]>([]);

  useEffect(() => {
    if (isOpen) {
      setFriendsList([]);
      setPage(1);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsLoading(true);
    handleGetData({ page, intl, limit: 50 }).finally(() => setIsLoading(false));
  }, [page, handleGetData, intl]);

  useEffect(() => {
    if (data?.entities && data.page === page) {
      if (page === 1) {
        setFriendsList(data.entities);
      } else {
        setFriendsList((prev) => prev.concat(data.entities || []));
      }
    }
  }, [data, page]);

  const loadMore = () => {
    if (!isLoading && data?.lastPage && page < data.lastPage) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const renderContent = useCallback(
    () => (
      <FriendsModalContent>
        {friendsList && friendsList.length > 0 && (
          <>
            <FriendsModalTurnoverSection />
            <FriendsModalTurnoverPlayersSection data={friendsList} />
            {isLoading && <LoadingSpin></LoadingSpin>}
            {data && page < data.lastPage && (
              <InfiniteScroll loadMore={loadMore} isLoading={isLoading} />
            )}
          </>
        )}

        {!isLoading && (data?.entities?.length ?? 0) <= 0 && (
          <FriendsModalNoRecordText>
            <FormattedMessage
              id="friendsPage.friendsModal.noFriendsText"
              values={{ br: <br /> }}
            />
          </FriendsModalNoRecordText>
        )}
      </FriendsModalContent>
    ),
    [friendsList, data, page, isLoading]
  );

  return (
    <ModalRedesigned
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({ id: 'friendsPage.friendsModal.title' })}
      isLoading={isLoading && friendsList.length <= 0}
      isScrollShadow={true}
    >
      {renderContent()}
    </ModalRedesigned>
  );
};

const mapStateToProps = createStructuredSelector({
  data: selectFriendsList,
});

const mapDispatchToProps = {
  handleGetData: getFriendsList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsPageFriendsModal);
