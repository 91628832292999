import styled from 'styled-components';
import BringBg from '@icons/friends/bringBg.png';

export const FriendsPageBring = styled.div`
  width: 100%;
  text-align: center;
  background-image: url(${BringBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  flex-shrink: 0;
  border-top: 1px solid #ffa600;
  border-bottom: 1px solid #ffa600;

  & span {
    position: relative;
    color: #a4ffff;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    -webkit-text-stroke: 2px #0f2031;
    white-space: nowrap;
    text-shadow: 0px 1px 0px #051e43;
    &::before {
      content: attr(data-text);
      color: #a4ffff;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-text-stroke: 0px #0f2031;
      white-space: nowrap;
    }
  }
`;
