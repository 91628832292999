import styled from 'styled-components/macro';

export const ItemsContainer = styled.div<{}>`
  position: relative;
`;
export const ItemsContainerTop = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  span {
    color: #6080b9;

    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const ItemsList = styled.div<{}>``;
export const ItemsListItem = styled.div<{}>`
  border-radius: 11px;
  padding: 10px 10px;
  background: #25428c;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  &:nth-child(2n) {
    background: #0a2e76;
  }
`;

export const ItemsListItemLeft = styled.div<{}>``;

export const ItemsListItemLeftRow = styled.div<{}>`
  /* display: flex;
  align-items: center; */
  display: grid;
  grid-template-columns: 40px 24px minmax(24px, 150px);
  place-items: center;
  gap: 5px;
  span {
    &:nth-of-type(1) {
      color: #8aaeec;
      text-align: right;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &:nth-of-type(2) {
      color: #8aaeec;
      font-family: Montserrat;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      justify-self: start;
    }
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

export const ItemsListItemRight = styled.div<{}>`
  margin-left: auto;
  span {
    color: #8aaeec;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
