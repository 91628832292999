import { LoadingSpinStyle } from '@components/Attoms/ModalRedesigned/components/LoadingSpin/My.styled';
import styled from 'styled-components/macro';

export const FriendsModalContent = styled.div`
  min-height: 300px;

  ${LoadingSpinStyle} {
    display: grid;
    place-items: center;
    margin-top: 10px;
  }
`;

export const FriendsModalNoRecordText = styled.h4`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  color: #8aaeec;
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
