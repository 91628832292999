export const getProfileApi = (page: any, gameId: any) => ({
  url: '/main/profile',
  method: 'GET',
  params: {
    page,
    gameId,
  },
  clientCache: true,
});

export const updateNickNameApi = (params: any) => ({
  url: '/user/update',
  method: 'PUT',
  data: { nickName: params.nickName },
});
