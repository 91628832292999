import styled from 'styled-components';
import InfoBg from '@icons/friends/infoBg1.png';

export const FriendsPageInfo = styled.div`
  background-image: url(${InfoBg});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;

  padding: 10px;
`;

export const FriendsPageInfoModalContent = styled.div`
  min-height: 300px;
  text-align: center;
`;
