import styled from 'styled-components/macro';

export const PlayersContainer = styled.div<{}>`
  position: relative;
`;
export const PlayersContainerTop = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  span {
    color: #6080b9;

    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const PlayersList = styled.div<{}>``;
export const PlayersListItem = styled.div<{}>`
  border-radius: 11px;
  padding: 10px 10px;
  background: #0a2e76;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  &:nth-child(2n) {
    background: #25428c;
  }
`;

export const PlayersListItemLeft = styled.div<{}>`
  span {
    color: #8aaeec;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const PlayersListItemRight = styled.div<{}>`
  margin-left: auto;
`;

export const PlayersListItemRightRow = styled.div<{}>`
  display: grid;
  grid-template-columns: 1fr 0.5fr minmax(40px, 1fr);
  gap: 5px;
  text-align: center;
  span {
    &:nth-of-type(1) {
      color: #8aaeec;
      text-align: right;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &:nth-of-type(2) {
      color: #6080b9;
      font-family: Montserrat;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  img {
    width: 18px;
    height: 18px;
  }
`;
