import { DashboardNavWrapper } from '@views/DashboardScreen/components/DashboardNav/My.styled';
import styled from 'styled-components';

export const FriendsPageWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const FriendsPageConatiner = styled.div`
  width: 100%;
  max-width: 414px;
  height: 100vh;
  max-height: 896px;
  background-color: #090d29;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: height 0.3s ease;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  /* height: 100%; */

  ${DashboardNavWrapper} {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 5;
  }
`;
