import styled, { keyframes } from 'styled-components/macro';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const zoomOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
`;

export const ModalWrapper = styled.div<{ isClosing: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 10;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)} 0.3s forwards;
`;

export const ModalContent = styled.div<{
  isClosing: boolean;
}>`
  border-radius: 11px;
  background-color: #25428c;
  margin: auto;
  position: relative;
  z-index: 11;
  width: 100%;
  max-height: 100%;
  max-width: 360px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  animation: ${({ isClosing }) => (isClosing ? zoomOut : zoomIn)} 0.3s forwards;
`;

export const ModalContentHeader = styled.div<{
  isBorderUnderTitle: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 15px 50px;
  flex: 0 1 50px;

  img {
    width: 100%;
  }

  &::before {
    position: absolute;
    content: '';
    display: ${({ isBorderUnderTitle }) =>
      isBorderUnderTitle ? 'block' : 'none'};
    left: 50%;
    bottom: 0;
    width: calc(100% - 14px);
    height: 1px;
    transform: translate(-50%, 0);
    background-color: #2a59c1;
  }
`;

export const TitleWithLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 15px;
`;

export const TitleHeader = styled.div`
  color: #7ea2df;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ModalMainContent = styled.div<{
  enableShadow: boolean;
  isBottomShadow: boolean;
  isTopShadow: boolean;
}>`
  flex: 1;
  padding: 10px;
  max-height: 714px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 10px;

  p {
    color: #8aaeec;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 30%;
    max-height: 100px;
    left: 0;
    top: 50px;
    background: linear-gradient(0deg, rgba(37, 66, 140, 0) 0%, #25428c 100%);
    z-index: 10;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isTopShadow }) => (isTopShadow ? 1 : 0)};
    display: ${({ enableShadow }) => (!enableShadow ? 'none' : 'block')};
  }
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 30%;
    max-height: 100px;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(37, 66, 140, 0) 0%, #25428c 100%);
    z-index: 10;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isBottomShadow }) => (isBottomShadow ? 1 : 0)};
    display: ${({ enableShadow }) => (!enableShadow ? 'none' : 'block')};
  }
`;

export const ModalFooterContent = styled.div<{
  enableShadow: boolean;
  isBottomShadow: boolean;
}>`
  width: 100%;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 70px;
    max-height: 100px;
    left: 0;
    top: -70px;
    background: linear-gradient(180deg, rgba(37, 66, 140, 0) 0%, #25428c 100%);
    z-index: 10;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isBottomShadow }) => (isBottomShadow ? 1 : 0)};
    display: ${({ enableShadow }) => (!enableShadow ? 'none' : 'block')};
  }
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  border: none;
  color: #333;
  font-size: 16px;
  right: 14px;
  top: 14px;
  cursor: pointer;
  z-index: 2;
`;
