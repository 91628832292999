import styled from 'styled-components';

export const FriendsPageInfoButtonCopy = styled.button`
  cursor: pointer;
  border-radius: 9px;
  border: 0.5px solid #ff0;
  background: linear-gradient(180deg, #fe8003 42%, #ffb028 100%);

  max-width: 68px;
  width: 100%;
  position: relative;

  img {
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;
  }

  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    height: 120%;
    width: 120%;
    border-radius: 100%;
    transform: translate(-50%, 0);
    top: -40px;

    background: linear-gradient(
      180deg,
      rgba(105, 250, 255, 0) -2.49%,
      #69faff 88.42%
    );
    opacity: 0.32;
    background-blend-mode: plus-lighter;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    height: 120%;
    width: 120%;
    transform: translate(-50%, 0);
    bottom: -46px;
    border-radius: 545.6px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffd700 0%,
      rgba(255, 215, 0, 0) 100%
    );
    opacity: 0.32;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

export const FriendsPageInfoButtonInvite = styled.button`
  cursor: pointer;
  border-radius: 9px;
  border: 0.5px solid #ff0;
  background: linear-gradient(180deg, #fe8003 42%, #ffb028 100%);
  position: relative;
  padding: 11px;
  display: block;
  width: 100%;
  overflow: hidden;

  & span {
    position: relative;
    color: #e9fff7;
    text-align: center;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    -webkit-text-stroke: 2px #9d1a1a;
    z-index: 2;
    white-space: nowrap;

    &::before {
      content: attr(data-text);
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-text-stroke: 0px #000;
      white-space: nowrap;
    }

    @media (max-width: 350px) {
      font-size: 20px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    height: 120%;
    width: 120%;
    border-radius: 100%;
    transform: translate(-50%, 0);
    top: -40px;

    background: linear-gradient(
      180deg,
      rgba(105, 250, 255, 0) -2.49%,
      #69faff 88.42%
    );
    opacity: 0.32;
    background-blend-mode: plus-lighter;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    height: 120%;
    width: 120%;
    transform: translate(-50%, 0);
    bottom: -79px;
    border-radius: 545.6px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffd700 0%,
      rgba(255, 215, 0, 0) 100%
    );
    opacity: 0.32;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

export const FriendsPageInfoButtons = styled.div`
  display: flex;
  gap: 10px;
`;
