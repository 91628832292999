import React, { useEffect, useState } from 'react';
import Logo from '@icons/svg/modal-logo.svg';

import {
  ModalWrapper,
  ModalContent,
  TitleHeader,
  ModalMainContent,
  ModalCloseButton,
  ModalContentHeader,
  TitleWithLogoContainer,
  ModalFooterContent,
} from './My.styled';

import CloseIconModalRedesigned from '@assets/icons/components/CloseIconModalRedesigned';
import LoadingSpin from './components/LoadingSpin';
import { useScrollWithShadow } from '@shared/utils/hooks/useScrollWithShadow';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  isLoading?: boolean;
  isCloseButton?: boolean;
  isBorderUnderTitle?: boolean;
  isScrollShadow?: boolean;
  titleWithLogo?: boolean;
  isDisableOverlayClick?: boolean;
  footer?: React.ReactNode;
}

const ModalRedesigned: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  isLoading = false,
  isCloseButton = true,
  isBorderUnderTitle = true,
  isScrollShadow = false,
  titleWithLogo = false,
  isDisableOverlayClick = false,
  footer,
}) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const {
    isBottomShadow: useIsBottomShadow,
    isTopShadow,
    onScrollHandler,
  } = useScrollWithShadow();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  return (
    <>
      <ModalWrapper
        onClick={() => {
          if (!isDisableOverlayClick) handleClose();
        }}
        isClosing={isClosing}
      >
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          isClosing={isClosing}
        >
          <ModalContentHeader isBorderUnderTitle={isBorderUnderTitle}>
            {titleWithLogo ? (
              <TitleWithLogoContainer>
                <img src={Logo} alt="logo" />
                <TitleHeader>{title}</TitleHeader>
              </TitleWithLogoContainer>
            ) : title ? (
              <TitleHeader>{title}</TitleHeader>
            ) : (
              <img src={Logo} alt="logo" />
            )}

            {isCloseButton && (
              <ModalCloseButton onClick={handleClose}>
                <CloseIconModalRedesigned width={18} height={18} />
              </ModalCloseButton>
            )}
          </ModalContentHeader>
          <ModalMainContent
            onScroll={onScrollHandler}
            enableShadow={isScrollShadow}
            isBottomShadow={!footer && useIsBottomShadow}
            isTopShadow={isTopShadow}
          >
            {!isLoading ? <>{children}</> : <LoadingSpin></LoadingSpin>}
          </ModalMainContent>

          {footer && (
            <ModalFooterContent
              enableShadow={isScrollShadow}
              isBottomShadow={useIsBottomShadow}
            >
              {footer}
            </ModalFooterContent>
          )}
        </ModalContent>
      </ModalWrapper>
    </>
  );
};

export default ModalRedesigned;
