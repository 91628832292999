import { Dispatch } from 'react';
import { AxiosResponse } from 'axios';
import { refreshToken } from '@redux/auth/actions';
import axiosInstance from './configAPI';
import { GA_CATEGORIES } from '@services/config';
import { gaUserSource, sendGAEvent } from '@shared/utils/helpers/helpers';
import { apiCache } from '@api/configAPI';
import ReactGA from 'react-ga4';
import { handleLogoutAction } from '@redux/auth/actions';
import { toast } from 'react-toastify';
import { getValueFromLocalStorage } from '@services/tokenStorageService';

interface IResponseError extends Error {
  response?: {
    status: number;
    data: {
      code: string;
    };
  };
}

interface IOptions {
  url: string;
  data?: object;
  method?: string;
  params?: object;
}

const handleUnauthorized = async (
  dispatch: Dispatch<any>,
  retry: any,
  options: IOptions
): Promise<any> => {
  if (!retry) {
    try {
      // @ts-ignore
      await refreshToken()(dispatch);
    } catch (error) {
      return Promise.reject(error);
    }

    try {
      return await http(dispatch, true)(options);
    } catch (reError) {
      return Promise.reject(reError);
    }
  }

  return Promise.reject(new Error('Maximum refresh token attempts exceeded'));
};

// состояние показа уведомления о бане
let isBanMessageShown = false;

const http =
  (dispatch: Dispatch<any>, retry?: boolean) =>
  async (options: IOptions): Promise<AxiosResponse> => {
    try {
      return await axiosInstance(options);
    } catch (error) {
      const err = error as IResponseError;

      if (!err.response) {
        console.error(`Network error [REQUEST FAILED: ${options.url}]`, error);
        return Promise.reject(err);
      }

      if (
        err.response?.status === 403 &&
        typeof err.response?.data === 'string' &&
        err.response?.data === 'Banned!' &&
        !isBanMessageShown
      ) {
        console.log('Banned [403] :: user is banned');
        isBanMessageShown = true;

        // Очищаем кэш
        apiCache.resetAll();

        // Отправляем событие в GA
        sendGAEvent('banned_user_logout', {
          category: GA_CATEGORIES.USER,
          user_source: gaUserSource(),
        });

        // Устанавливаем состояние бана
        dispatch({
          type: 'SET_USER_BANNED',
          payload: true,
        });

        const isTelegram = getValueFromLocalStorage('isTelegram') === 'true';

        if (isTelegram) {
          toast.error('You have been banned from Telegram access', {
            autoClose: 5000,
            onClose: () => {
              dispatch(handleLogoutAction());
              window.location.href = '/';
              isBanMessageShown = false;
            },
          });
        } else {
          toast.error('You have been banned', {
            autoClose: 5000,
            onClose: () => {
              dispatch(handleLogoutAction());
              window.location.href = '/';
              isBanMessageShown = false;
            },
          });
        }

        return Promise.reject({
          response: {
            status: 403,
            data: 'You have been banned',
          },
        });
      }

      if (err.response.status === 401) {
        console.log('Unauthorized [401] :: refreshing access token');
        try {
          return await handleUnauthorized(dispatch, retry, options);
        } catch (e) {
          return Promise.reject(e);
        }
      }

      return Promise.reject(err);
    }
  };

export default http;
