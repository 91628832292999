import styled from 'styled-components/macro';
import ActiveNavBg from '@icons/home/nav/active.png';

export const DashboardNavWrapper = styled.div`
  background: linear-gradient(180deg, #13142c 0%, #151b38 100%);
  box-shadow: 0px 1px 0px 0px #1d4595;
  display: flex;
  justify-content: center;
  position: relative;
  /* gap: 5px; */
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 4px;
    height: 1px;
    background-color: #1d4595;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0px;
    height: 4px;
    background-color: #1a2b57;
  }
`;

export const NavItem = styled.div<{ active: boolean }>`
  position: relative;
  width: calc(100% / 5 + 15px);
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  padding: 13px 5px;
  padding-bottom: 5px;
  z-index: 1;

  background-image: url(${({ active }) => (active ? ActiveNavBg : 'unset')});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  display: flex;
  flex-direction: column;
  text-align: center;
  img {
    height: 35px;
  }

  &:nth-child(2) {
    img {
      margin-right: 10px;
    }
  }

  @media (max-width: 380px) {
    &:nth-child(2) {
      img {
        margin-left: unset;
        align-self: unset;
      }
    }
  }

  span {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
  }
`;

export const NavText = styled.div<{ active: boolean }>`
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 0;

  color: #3674dd;

  text-align: center;
  font-family: Montserrat;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 360px) {
    font-size: 8px;
  }
`;
