import React, { ComponentType, useState } from 'react';

import {
  FriendsModalTurnover,
  FriendsModalTurnoverRow,
  FriendsModalTurnoverRowAmount,
  FriendsModalTurnoverRowIcon,
  FriendsModalTurnoverRowLeft,
  FriendsModalTurnoverRowRight,
  FriendsModalTurnoverTitle,
  TurnoverSeparatorLine,
} from './My.styled';
import { FormattedMessage, useIntl } from 'react-intl';

import TurnoverSeparatorLineIcon from '@icons/friends/modal/friends/turnover_separator_line.svg';
import UsdtIcon from '@icons/svg/USDT.svg';
import TonIcon from '@icons/svg/TON.svg';
import WtIcon from '@icons/svg/WT.svg';

const currencyIconMap = {
  TON: TonIcon,
  USDT: UsdtIcon,
  WT: WtIcon,
};

import { balanceFormatter } from '@shared/utils/helpers/helpers';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectProfileInfo } from '@redux/profile/selectors';
import { IGetProfileResponseModel } from '@common/types/profile.types';

interface FriendsModalTurnoverProps {
  profileInfo: null | IGetProfileResponseModel;
}

const FriendsModalTurnoverSection: ComponentType<FriendsModalTurnoverProps> = ({
  profileInfo,
}) => {
  const intl = useIntl();
  return (
    <FriendsModalTurnover>
      <FriendsModalTurnoverTitle>
        <FormattedMessage id="friendsPage.friendsModal.turnover" />
      </FriendsModalTurnoverTitle>
      <FriendsModalTurnoverRow>
        <FriendsModalTurnoverRowLeft>
          <FriendsModalTurnoverRowAmount>
            {balanceFormatter(
              Number(
                profileInfo?.user.referralFriendsTotalAmount.find(
                  (item) => item.currencyCode === 'USDT'
                )?.amount || 0
              )
            )}
          </FriendsModalTurnoverRowAmount>
          <FriendsModalTurnoverRowIcon>
            <img
              src={currencyIconMap['USDT' as 'TON' | 'USDT' | 'WT']}
              alt=""
            />
            <span>USDT</span>
          </FriendsModalTurnoverRowIcon>
        </FriendsModalTurnoverRowLeft>
        <TurnoverSeparatorLine>
          <img src={TurnoverSeparatorLineIcon} alt="" />
        </TurnoverSeparatorLine>
        <FriendsModalTurnoverRowRight>
          <FriendsModalTurnoverRowAmount>
            {balanceFormatter(
              Number(
                profileInfo?.user.referralFriendsTotalAmount.find(
                  (item) => item.currencyCode === 'TON'
                )?.amount || 0
              )
            )}
          </FriendsModalTurnoverRowAmount>
          <FriendsModalTurnoverRowIcon>
            <img src={currencyIconMap['TON' as 'TON' | 'USDT' | 'WT']} alt="" />
            <span>TON</span>
          </FriendsModalTurnoverRowIcon>
        </FriendsModalTurnoverRowRight>
      </FriendsModalTurnoverRow>
    </FriendsModalTurnover>
  );
};

const mapStateToProps = createStructuredSelector({
  profileInfo: selectProfileInfo,
});
export default connect(mapStateToProps)(FriendsModalTurnoverSection);
