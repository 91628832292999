import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { InfiniteScrollWrap } from './My.styled';

type InfiniteScrollProps = {
  isLoading: boolean;
  loadMore: () => void;
};

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  isLoading,
  loadMore,
}) => {
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!isLoading && inView) {
      loadMore();
    }
  }, [inView]);

  return <InfiniteScrollWrap ref={ref} />;
};

export default InfiniteScroll;
