import React, { useState } from 'react';
import { ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  FriendsPageInfoList,
  FriendsPageInfoListItem,
  FriendsPageInfoListItemTitle,
  FriendsPageInfoListItemText,
  FriendsPageInfoListItemInfo,
} from './My.styled';
import FriendsPageInfoAllBetsModal from '../modals/FriendsPageInfoAllBetsModal';
import FriendsPageInfoRefsModal from '../modals/FriendsPageInfoRefsModal';
import FriendsPageInfoToYouModal from '../modals/FriendsPageInfoToYouModal';

interface FriendsPageInfoListSectionProps {}

const FriendsPageInfoListSection: ComponentType<
  FriendsPageInfoListSectionProps
> = () => {
  const [openModal, setOpenModal] = useState<number | null>(null);

  const handleOpenModal = (index: number) => {
    setOpenModal(index);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <>
      <FriendsPageInfoList>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((item) => (
          <FriendsPageInfoListItem key={item}>
            <FriendsPageInfoListItemTitle>
              <FormattedMessage
                id={`friendsPage.info.item${item}.title`}
                values={{
                  span: (...chunks) => <span>{chunks}</span>,
                }}
              />
            </FriendsPageInfoListItemTitle>
            <FriendsPageInfoListItemText>
              <FormattedMessage id={`friendsPage.info.item${item}.text`} />
            </FriendsPageInfoListItemText>
            <FriendsPageInfoListItemInfo onClick={() => handleOpenModal(item)}>
              i
            </FriendsPageInfoListItemInfo>
          </FriendsPageInfoListItem>
        ))}
      </FriendsPageInfoList>

      {openModal === 1 && (
        <FriendsPageInfoAllBetsModal isOpen={true} onClose={handleCloseModal} />
      )}
      {openModal === 2 && (
        <FriendsPageInfoRefsModal isOpen={true} onClose={handleCloseModal} />
      )}
      {openModal === 3 && (
        <FriendsPageInfoToYouModal isOpen={true} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default FriendsPageInfoListSection;
