import { AppConfig, DEFAULT_IMAGE_URLS } from '@services/config';
import moment from 'moment';
import { EGames } from '@common/types/dashboard.types';
import { getValueFromLocalStorage } from '@services/tokenStorageService';

import tonIcon from '@icons/svg/TON.svg';
import usdtIcon from '@icons/svg/USDT.svg';
import wtIcon from '@icons/svg/WT.svg';
import xClarIcon from '@icons/svg/xclar.svg';
import ReactGA from 'react-ga4';
import { IS_GA_ENABLED } from '@services/config';

export const getImageUrl = (image: string | null): string | null => {
  if (!image) {
    return null;
  }

  // Check if image URL is already absolute
  if (image.startsWith('http://') || image.startsWith('https://')) {
    return image;
  }

  return `${AppConfig.apiImages}${image}`;
};

export const getCurrentTimezoneOffset = () => {
  const offsetInMinutes = new Date().getTimezoneOffset();

  // getTimezoneOffset() returns the offset with the opposite sign, so we need to negate it
  const offsetInHours = -offsetInMinutes / 60;

  // Create the timezone string
  const timezone = `GMT${offsetInHours >= 0 ? '+' : ''}${Math.abs(
    offsetInHours
  )}`;

  return timezone;
};

export const formatToCustomDate = (date: string) =>
  moment(date).format('ddd, DD MMM, YYYY');

export const validExtensionsImage = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.svg',
  '.webp',
];

export const getAvatarUrl = (imageUrl: string | undefined) => {
  // Перевірка на відповідність одному з допустимих розширень
  const hasValidExtension =
    imageUrl && validExtensionsImage.some((ext) => imageUrl!.endsWith(ext));
  const isDefault =
    !hasValidExtension || DEFAULT_IMAGE_URLS.includes(imageUrl!);

  return {
    url: isDefault ? '' : imageUrl!,
    isDefault,
  };
};

export const calculateWinningsAndRate = (results: any, allDuels: any) => {
  let result;

  if (results.Won === results.Lost) {
    result = results.Won * 0.8 - results.Won;
  } else if (results.Won - results.Lost >= 0) {
    result = (results.Won - results.Lost) * 0.8;
  } else {
    result = results.Won * 0.8 - results.Lost;
  }

  const winRateValue = (results.Won / allDuels.length) * 100;

  return {
    winnings: Math.round(result * 100) / 100, // Заокруглення до двох знаків після коми
    winRate: Math.round(winRateValue * 100) / 100, // Заокруглення до двох знаків після коми, якщо вам це потрібно
  };
};

export const checkURL = () => {
  const currentURL = window.location.hostname;

  if (currentURL === 'cryptoparty.games') {
    return 'live';
  } else {
    return 'demo';
  }
};

export const gaGameName = (gameName: string) => {
  switch (gameName) {
    case EGames.BearHamsterBull:
      return '_bhb';
    case EGames.FiveInAll:
      return '_fia';
    case EGames.TotalZero:
      return '_tz';
    case EGames.TwentyOne:
      return '_to';
    case EGames.Pentagram:
      return '_ptg';
    case EGames.Penalty:
      return '_pen';
    default:
      return '';
  }
};

export const gaTelegram = () => {
  if (getValueFromLocalStorage('isTelegram') === 'true') {
    return '_tg';
  } else {
    return '';
  }
};

export const gaUserSource = () => {
  if (getValueFromLocalStorage('isTelegram') === 'true') {
    return 'tg_user';
  } else {
    return 'web_user';
  }
};

export const balanceFormatter = (num: number) => {
  if (num >= 1_000_000) {
    return `${Math.floor(num / 1_000_000)}M`;
  }
  if (num >= 1_000) {
    return `${Math.floor(num / 1_000)}K`;
  }
  return num.toString();
};

export const getCurrencyIcon = (currencyCode: string) => {
  switch (currencyCode) {
    case 'WT':
      return wtIcon;
    case 'USDT':
      return usdtIcon;
    case 'TON':
      return tonIcon;
    case 'XCLAR':
      return xClarIcon;
    default:
      return wtIcon;
  }
};

export const sendGAEvent = (eventName: any, eventParams: any) => {
  // Если GA отключена или это DEMO-категория - не отправляем
  if (!IS_GA_ENABLED || eventParams.category?.startsWith('DEMO_')) {
    return;
  }
  ReactGA.event(eventName, eventParams);
};
