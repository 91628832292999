import React, { createContext, useContext, useState, ReactNode } from 'react';
import { State, StoreHelpers } from 'react-joyride';

interface OnboardingContextType {
  setOnboardingHelpers: (helpers: StoreHelpers) => void;
  go: (index: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  startTour: () => void;
  resetTour: () => void;
  pauseTour: () => void;
  resumeTour: () => void;
  info: () => State | undefined;
  run: boolean;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(
  undefined
);

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [helpers, setHelpers] = useState<StoreHelpers>();
  const [run, setRun] = useState(false);

  const setOnboardingHelpers = (helpers: StoreHelpers) => {
    setHelpers(helpers);
  };

  const go = (index: number) => helpers?.go(index);
  const nextStep = () => helpers?.next();
  const prevStep = () => helpers?.prev();
  const startTour = () => setRun(true);
  const resetTour = () => helpers?.reset(true);
  const pauseTour = () => setRun(false);
  const resumeTour = () => setRun(true);
  const info = () => helpers?.info();

  return (
    <OnboardingContext.Provider
      value={{
        setOnboardingHelpers,
        go,
        nextStep,
        prevStep,
        startTour,
        resetTour,
        pauseTour,
        resumeTour,
        run,
        info,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      'useOnboarding должен использоваться внутри OnboardingProvider'
    );
  }
  return context;
};
