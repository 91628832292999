import ModalRedesigned from '@components/Attoms/ModalRedesigned';
import React, { ComponentType, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import { FriendsShareModalContent } from './My.styled';
import { AppConfig } from '@services/config';

interface FriendsPageShareModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  shareUrl: string;
}

const FriendsPageShareModal: ComponentType<FriendsPageShareModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  shareUrl,
}) => {
  const intl = useIntl();

  return (
    <ModalRedesigned
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({ id: 'friendsPage.shareModalText' })}
      isLoading={isLoading}
    >
      <FriendsShareModalContent>
        <TelegramShareButton
          url={`${AppConfig.telegramAppUrl}/app?startapp=${shareUrl}`}
        >
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
      </FriendsShareModalContent>
    </ModalRedesigned>
  );
};

export default FriendsPageShareModal;
