import styled from 'styled-components';

export const FriendsPageInfoList = styled.ul`
  margin-bottom: 10px;
`;

export const FriendsPageInfoListItem = styled.li`
  position: relative;
  border-radius: 7px;
  border: 1px solid rgba(126, 162, 223, 0.61);

  padding: 8px 18px;
  padding-right: 60px;
  overflow: hidden;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    opacity: 0.61;
    background: linear-gradient(
      270deg,
      rgba(128, 140, 248, 0.7) 0%,
      rgba(0, 6, 35, 0) 100%
    );
    background-blend-mode: plus-lighter;
  }

  &:not(:last-child) {
    margin-bottom: 7px;
  }
`;

export const FriendsPageInfoListItemContent = styled.li`
  position: relative;
`;

export const FriendsPageInfoListItemTitle = styled.h6`
  color: #2ca8d8;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  span {
    color: #ffaf26;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-right: 5px;
  }

  margin-bottom: 6px;
`;
export const FriendsPageInfoListItemText = styled.p`
  color: #85abea;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FriendsPageInfoListItemInfo = styled.button`
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 35px;
  max-width: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  border-radius: 7px;

  background: #15217a;

  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);

  color: #7ea2df;

  text-align: right;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  transition: 0.3s background-color ease-in-out;

  &:hover {
    background-color: #243298;
  }
`;
