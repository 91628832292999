// constants.ts
export const notificationActionTypes = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAIL: 'GET_NOTIFICATIONS_FAIL',

  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FAIL: 'DELETE_NOTIFICATION_FAIL',

  CLEAR_ERROR: 'CLEAR_ERROR',
};
