import React from 'react';
import { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { FriendsPageBring } from './My.styled';
import { useIntl } from 'react-intl';

interface FriendsPageBringProps {}

const FriendsPageBringSection: ComponentType<FriendsPageBringProps> = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <>
      <FriendsPageBring>
        <span data-text={intl.formatMessage({ id: 'friendsPage.bring' })}>
          {intl.formatMessage({ id: 'friendsPage.bring' })}
        </span>
      </FriendsPageBring>
    </>
  );
};

export default FriendsPageBringSection;
