import React from 'react';
import { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';

import BackArrowIcon from '@icons/friends/back-arrow.svg';
import { FormattedMessage } from 'react-intl';
import { FriendsPageHeader, FriendsPageHeaderText } from './My.styled';

interface FriendsPageHeaderProps {}

const FriendsPageHeaderSection: ComponentType<FriendsPageHeaderProps> = () => {
  const navigate = useNavigate();

  return (
    <>
      <FriendsPageHeader>
        <img
          src={BackArrowIcon}
          alt="back"
          onClick={() => navigate('/dashboard')}
        />
        <FriendsPageHeaderText>
          <FormattedMessage id="friendsPage.pageTitle" />
        </FriendsPageHeaderText>
      </FriendsPageHeader>
    </>
  );
};

export default FriendsPageHeaderSection;
