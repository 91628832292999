import {
  IGetFriendsListModel,
  IGetReferralReceivedRewardListModel,
} from '@common/types/referral.types';
import { createSelector } from 'reselect';

export const selectReferral = (state: any) => state.get('referral');

export const selectFriendsList = createSelector(
  selectReferral,
  (substate): IGetFriendsListModel => substate.getIn(['friends', 'data'])
);
export const selectFriendsListLoading = createSelector(
  selectReferral,
  (substate) => substate.getIn(['friends', 'isLoading'])
);

export const selectReferralIsLoadingClaim = createSelector(
  selectReferral,
  (substate) => substate.getIn(['claimReward', 'isLoading'])
);

export const selectReceivedRewards = createSelector(
  selectReferral,
  (substate): IGetReferralReceivedRewardListModel =>
    substate.getIn(['receivedRewards', 'data'])
);
export const selectReceivedRewardsLoading = createSelector(
  selectReferral,
  (substate) => substate.getIn(['receivedRewards', 'isLoading'])
);
