import { authActionTypes } from '@redux/auth/constants';
import { Reducer } from 'redux';
import { fromJS, Map } from 'immutable';
import { settingsActionTypes } from './contstants';

const storedLang = localStorage.getItem('appLanguage') || 'en';

interface InitialStateInterface {
  lang: string;
  isLoading: boolean;
}

const initialState: InitialStateInterface = {
  lang: storedLang,
  isLoading: false,
};

// @ts-ignore
const settingReducer: Reducer<Map<string, any>> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case authActionTypes.RESET_ALL:
      return fromJS(initialState);

    case settingsActionTypes.SET_LANGUAGE:
      localStorage.setItem('appLanguage', payload);
      return state.set('lang', payload);

    default:
      return state;
  }
};

export default settingReducer;
